// Breakpoints
$desktop-breakpoint: 1024px;
$mobile-breakpoint: 360px;

// Variables
$title-color: #3d4043;
$subtitle-color: #003e94;
$max-width-content-container: 360px;

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500&display=swap');

// Animations

.alpha-intent .content-container,
.alpha-intent .custom-component {
    opacity: 0;
    visibility: hidden;
}

.alpha-intent .content-container.is-visible,
.alpha-intent .custom-component.is-visible {
    opacity: 1;
    visibility: visible;
    transition: all 1s ease-in-out;
}
// Main

#alpha-intent-content {
    width: 100%;
    margin: 0 auto;
    padding: 16px !important;

    > * {
        max-width: 740px;
        margin-left: auto;
        margin-right: auto;
    }

    > .ai-content--offer,
    > .multi-offer {
        max-width: 980px;
    }
}

.day-step,
.month-step,
.year-step,
.year-buttons,
.make-buttons,
.model-buttons,
.question-answers,
.yes-no-button-container {
    margin-top: 25px !important;

    @media (min-width: $desktop-breakpoint) {
        margin-top: 40px !important;
    }
}

// Basic Components
img {
    max-width: 100%;
    max-height: 100%;
}

.input-fields {
    display: grid;
    grid-gap: 15px;

    .input-group {
        span.error-message {
            &.ai-input-invalid {
                max-width: $max-width-content-container;
            }
        }
    }
}

#alpha-intent-form {
    .height-fields {
        margin: auto;
        max-width: $max-width-content-container;
    }

    .weight-fields {
        margin: auto;
        max-width: $max-width-content-container;
    }
}

input {
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    width: 100%;
    max-width: $max-width-content-container;

    background: white;
    border: 1px solid #a6a6a6;

    color: black;
    height: 75px;
    padding: 22px;

    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}
::placeholder {
    color: #a6a6a6;
}

label {
    display: block;
    margin: 0 auto;
    max-width: $max-width-content-container;
    width: 100%;
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: black;
    text-align: left;
}

h3 {
    mark {
        background: linear-gradient(to top, #b2f4d7 36%, transparent 0%);
        color: inherit;
    }
}

button {
    cursor: pointer;

    font-size: 16px;
    line-height: 24px;
    min-height: 65px;
    text-align: center;

    background-color: #ffffff;

    border: 1px solid #a6a6a6;
    border-bottom: 4px solid #a6a6a6;

    transition: ease-in box-shadow 160ms, ease-in border 160ms;

    &:hover {
    }

    &.selected {
        box-shadow: inset 0 0 0 4px #1a3b51;
        border-color: transparent;
    }
}

// Custom Components

#tcpa-text {
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 0;
}

.input-group + label {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
#alpha-intent-progress-bar {
    padding: 0 !important;
    background-color: #efefef;

    .ai-progress-bar-container {
        border-radius: 0 5.5px 5.5px 0;
        background-color: #00c8a5 !important;
    }
}

#alpha-intent-form {
    margin-top: 30px;
}

.question-answers {
    &.multi-select {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        @media (min-width: $desktop-breakpoint) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 25px;
        }

        button {
            overflow-wrap: break-word;
        }
        .submit-button {
            @media (min-width: $desktop-breakpoint) {
                grid-column: span 2;
            }
        }
    }

    .question-answer-button {
        max-width: $max-width-content-container;
        width: 100%;
        margin: 0 auto;
    }
}
.question-answers.yes-no-answers {
    .button-yes,
    .button-no {
        display: inline-grid;
        grid-template-columns: 1fr auto;
        place-items: center start;
        padding: 0px 30px;
        width: 100%;
        max-width: 165px !important;
        height: 90px;
        @media (min-width: $desktop-breakpoint) {
            max-width: 200px !important;
        }
        &::after {
            content: '';
            width: 30px;
            height: 30px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    .button-yes::after {
        background-image: url(http://cdn.openfinancial.co/assets/2022/08/24/CHECK.svg);
    }
    .button-no::after {
        background-image: url(http://cdn.openfinancial.co/assets/2022/08/24/X.svg);
        width: 25px;
        height: 25px;
    }
}

.gender-container {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    max-width: $max-width-content-container;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    label {
        display: grid;
        padding: 0 30px;
        margin: 0 !important;
        grid-template-columns: 1fr auto;
        height: 75px;
        place-items: center start;
        border: 1px solid #a6a6a6;
        border-bottom: 4px solid #a6a6a6;
        width: auto;
        cursor: pointer;
        transition: ease-in border 160ms, ease-in box-shadow 160ms, ease-in padding 160ms;
        &::after {
            content: '';
            width: 28px;
            height: 28px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    label[for='ai-male-input']::after {
        background-image: url(http://cdn.openfinancial.co/assets/2022/08/23/MALE.svg);
    }
    label[for='ai-female-input']::after {
        background-image: url(http://cdn.openfinancial.co/assets/2022/08/23/FEMALE.svg);
    }
    input[type='radio']:checked + label {
        box-shadow: inset 0 0 0 4px #1a3b51;
        border: 0;
        padding: 3px 30px;
    }
    .error-message {
        grid-column: span 2;
        text-align: center;
    }
}

.fullname-field {
    label:before {
        content: '';
        background: url(https://cdn.financebuzz.com/static/svg/auto-insurance-funnel/lock.svg);
        background-repeat: no-repeat;
        background-size: 10px;
        width: 21px;
        background-position: 6px 7px;
        margin-left: -3px;
        height: 22px;
    }
}

.question-footer,
.tcpa-text {
    text-align: left;
    font-size: 1.1rem;
    color: #a6a6a6;
    padding-top: 2rem;
    max-width: 360px;
    margin: 0 auto;
    line-height: 16px;
    display: block;

    a {
        color: #0ba2ff;
        font-weight: 500;

        &:hover {
            color: #0ba2ff;
        }
    }
}

.ai-progression-time-estimation {
    display: flex;
    font-family: Arial;
    font-size: 18px;
    letter-spacing: 0.3px;
    color: #3d4043;
    line-height: normal;
    width: auto !important;
}

.email-avarage-time-text {
    display: flex;
    font-family: Arial;
    font-size: 18px;
    letter-spacing: 0.3px;
    color: #3d4043;
    line-height: normal;

    &:before {
        content: '';
        background: url(https://cdn.financebuzz.com/static/svg/auto-insurance-funnel/info.svg);
        background-repeat: no-repeat;
        background-size: 23px;
        width: 86px;
        margin-left: 5px;
    }

    b {
        display: contents;
        font-weight: bold;
    }
}

.error-message {
    display: block;
    margin-top: 1px;
    font-weight: 700;
    font-size: 11px;
    line-height: 24px;
    /* or 133% */
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    text-align: center;

    color: #c24a42;
}

.yes-no-button-container {
    .yes-button,
    .no-button {
        width: 45%;
        height: 112px;
        font-size: 18px;
        max-width: 160px;
        margin-bottom: 16px;
        text-transform: uppercase !important;

        &:nth-child(2) {
            margin-left: 8px !important;
        }

        @media (min-width: $desktop-breakpoint) {
            width: 100%;
            max-width: 224px;
        }
    }
}

.main-heading {
    font-style: italic;
    font-weight: 100;
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
}

.question-object,
#ai-form-app,
#multiquestion-group-container {
    .question-title,
    h2,
    h1,
    .dob-container h3 {
        font-family: 'Lora', serif;
        margin: 0 auto 15px;

        max-width: 560px;
        font-size: 32px;
        line-height: 34px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
    }
}

.custom-component h1 + h2,
h3.question-subtitle,
h3.form-subtitle {
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

@media (min-width: $desktop-breakpoint) {
    .question-object,
    #ai-form-app {
        .question-title,
        h2 {
            font-size: 48px;
            line-height: 61px;
            max-width: 760px;
        }

        .question-subtitle {
            font-size: 22px;
        }
    }
}

.submit-button,
.button-submit {
    display: block;
    width: 100%;
    max-width: $max-width-content-container;
    border: none;
    height: 75px;
    margin: auto;
    color: white;
    font-size: 18px;
    border-radius: 0px;
    position: relative;
    font-weight: normal;
    background-color: #c24a42;
    margin-top: 20px;
    border-bottom: 4px solid #8f342e;

    @media (min-width: $desktop-breakpoint) {
        height: 90px;
        max-width: 425px;
        max-width: $max-width-content-container;
    }

    &:after {
        top: 0;
        width: 15px;
        right: 23px;
        content: '';
        height: 100%;
        position: absolute;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(http://cdn.openfinancial.co/assets/2022/08/16/right-arrow.svg);
    }

    &:hover {
        opacity: 1 !important;
        color: white !important;
        background-color: #c24a42 !important;
    }

    &:disabled {
        opacity: 0.5 !important;
        background-color: #c24a42 !important;

        &:hover {
            color: white !important;
            box-shadow: none !important;
        }
    }
}

// Auto selector and buttons DOB
.automobile-container,
.dob-container {
    .day-step,
    .month-step,
    .model-buttons,
    .make-buttons,
    .year-step,
    .year-buttons,
    .month-step {
        grid-gap: 5px !important;
        grid-template-columns: repeat(auto-fill, 31%) !important;

        @media (min-width: $desktop-breakpoint) {
            grid-template-columns: repeat(auto-fill, 100px) !important;
        }
    }

    .make-buttons,
    .model-buttons {
        grid-template-columns: repeat(auto-fill, 48%) !important;

        @media (min-width: $desktop-breakpoint) {
            grid-template-columns: repeat(auto-fill, 32%) !important;
        }
    }

    .back-button-container {
        text-align: left;
    }

    .show-all-button,
    .expand-button {
        width: 100%;
        max-width: 100%;
        margin-top: 15px !important;
    }

    .back-button {
        border: none;
        min-height: 0;
        font-size: 10px;
        max-width: 150px;
        color: #919191;
        box-shadow: none;
        font-weight: normal;
        background-color: #fafdff;

        &:hover {
            border: none !important;
            box-shadow: none !important;
            background-color: #ffffff !important;
        }
    }

    .car-config-container {
        + .custom-component h2 {
            color: #919191;
            font-weight: normal;
            font-family: Rubik, Helvetica, Arial, sans-serif;
        }

        h4 {
            font-size: 35px;
        }
    }
}

.dob-multifield-container {
    margin: auto;
    max-width: $max-width-content-container;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    @media (min-width: $desktop-breakpoint) {
        max-width: 560px;
    }

    .input-wrapper-multifield {
        margin-right: 0px !important;

        input {
            height: 75px;
            width: 100% !important;
            padding: 0 13px !important;
            border: solid 1px #a6a6a6;
            font-weight: 400 !important;
            font-size: 18px !important;
            line-height: 24px !important;
        }
    }
}

// Timer
.base-timer__path-remaining {
    color: #00c8a5 !important;
}

.base-timer__label {
    display: none !important;
}

.base-timer__svg {
    width: 62px;
    height: 62px;
}

.base-timer {
    width: 62px;
    height: 62px;
    margin: auto;
    margin-bottom: 16px;
}

// Address Fields

.address-suggestions > * {
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif !important;
}

.address-suggestions div {
    padding: 8px !important;
}

div#address-autosuggest input:last-child {
    margin-top: 4px;
}

.address-fields {
    max-width: $max-width-content-container;
    margin: auto;
}

#address-autosuggest {
    position: relative;
}

.address-suggestions {
    border: 1px solid lightgray;

    &.is-visible {
        position: absolute;
        background: white;
        width: 100%;
        text-align: left;
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 4px;
        z-index: 1;

        div:hover {
            background: #e7e7e7;
            cursor: pointer;
        }
    }

    div {
        padding: 8px;
    }
}

// Multi Question
.multiquestion-group-container-question {
    width: 100%;
    margin: auto;
    max-width: 430px;

    &:first-of-type {
        margin-top: 40px;
    }

    h2 {
        margin: 0 !important;
        font-weight: 900 !important;
        text-align: left !important;
        font-size: 16px !important;
    }

    .question-answers {
        display: flex;
        margin-top: 10px !important;
        place-content: space-between;
        margin-bottom: 40px !important;

        .question-answer-button {
            height: 45px;
            width: 46%;
        }
    }
}
.question-answer-button {
    // Question Answer Button: Image Only
    &.question-answer-cta-text {
        .button-text {
            display: none;
        }
        .checkmark {
            display: none;
        }
    }

    // Question Answer Button: Image Only
    &.question-answer-cta-img {
        .button-text {
            display: none;
        }
        .checkmark {
            display: none;
        }
        // Image Styles
        img {
        }
    }
    // Question Answer Button: CTA Text + Image
    &.question-answer-cta-text-image {
        display: grid;
        grid-template-columns: auto 1fr;
        place-items: center;
        width: 100%;
        max-width: $max-width-content-container;
        padding: 10px;
        padding-right: 40px;
        @media (min-width: $desktop-breakpoint) {
            grid-template-columns: 1fr;
            height: 145px;
            padding-right: 10px;
        }
        // Image Styles
        img {
            grid-row: 1;
            width: 32px;
            height: 32px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            object-fit: contain;
            grid-column: 1;
            grid-row: 1;

            @media (min-width: $desktop-breakpoint) {
                width: 60px;
                height: 60px;
            }
        }
        .button-text {
            display: none;
        }
        .checkmark {
            display: none;
        }
    }
}

//Specific Benefit Programs Multi-Select Questions

.question-375 {
    .multi-select {
        display: grid;
        place-items: center;
        grid-gap: 10px;
        grid-template-columns: 1fr;
        @media (min-width: $desktop-breakpoint) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        .submit-button {
            @media (min-width: $desktop-breakpoint) {
                margin-top: 33px;
                grid-column: span 3;
            }
        }
        .question-answer-button {
            @media (min-width: $desktop-breakpoint) {
                max-width: 230px;
                height: 145px;
            }
            .button-text {
                display: none;
            }
            .checkmark {
                display: none;
            }
        }
    }
}

[data-object-type='offer'][data-object-sub-type='api_integration'][data-object-creative-type='html'] {
    h1 {
    }

    .multi-offer {
    }

    .mobile_link {
        @media (min-width: $desktop-breakpoint) {
            display: none;
        }
    }
}

[data-object-type='offer'][data-object-sub-type='linkout'][data-object-creative-type='html'] {
}

// CRM OFFER
[data-object-type='offer'][data-object-sub-type='hosted'] {
    h2.offer-title {
        @media (min-width: $desktop-breakpoint) {
        }
    }

    .offer-buttons {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 25px;
        margin: 50px auto 0px;
        place-items: center;

        button#offer-take-button-yes,
        button#offer-take-button-no {
            grid-template-columns: 1fr;
            place-items: center;
            width: 100%;
            max-width: 425px !important;
            &::after {
                display: none;
            }
        }
        button#offer-take-button-yes {
            background-color: #c24a42;
            color: white;
            border: none;
            border-bottom: 4px solid #8f342e;
        }
    }

    .offer-body {
        font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-size: 16px;
        margin: 20px auto;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 560px;

        @media (min-width: $desktop-breakpoint) {
        }
    }

    .offer-footer {
        text-align: left;
        font-size: 11px;
        color: #a6a6a6;
        max-width: 560px;
        margin: 0 auto;
        font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
        display: block;
    }
}

.mobile-local {
    display: block;

    @media (min-width: $desktop-breakpoint) {
        display: none;
    }
}

.non-mobile,
.non_mobile {
    display: none;

    @media (min-width: $desktop-breakpoint) {
        display: block;
    }
}

.ai-content--offer {
    .creatives {
        .path-offer {
            @media (min-width: $desktop-breakpoint) {
            }
        }

        // For now we are hiding accordian list on desktop
        .accordianList,
        h2.question-title.non-mobile ~ h4 {
            @media (min-width: $desktop-breakpoint) {
                display: none;
            }
        }

        // This is duplicated link now we are hiding accordian list on desktop
        // https://a.cl.ly/04uLqJO6
        .non_mobile ~ a {
            display: none;
        }

        ul {
            li {
                list-style: none;
                display: block;
                position: relative;
                padding-left: 20px;
                text-align: left;

                @media (max-width: $desktop-breakpoint) {
                    font-size: 1.4rem;
                }

                &:before {
                    display: block;
                    content: '';
                    background: #000;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 8px;
                    left: 0;
                    border-radius: 0.4rem;
                }
            }
        }

        .offer-content {
            &.brand {
                z-index: 1;
                text-align: center;
                align-self: center;
                align-items: center;
                background: #fff;
                box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.2);
                border-radius: 0.4rem;
                width: 45%;
                padding: 4rem;

                @media (max-width: $desktop-breakpoint) {
                    padding: 0;
                    width: 100%;
                    min-height: 120px;
                    text-align: center;
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;
                    align-self: center;
                    background: #fff;
                    border-radius: 0.4rem;
                    padding: 2rem 2rem 2rem;
                    box-shadow: 1px 1px 13px rgba(0, 0, 0, 0.2);
                }

                .offer-logo {
                    width: 200px;
                }
            }

            &:not(.brand) {
                text-align: left;

                @media (min-width: $desktop-breakpoint) {
                    width: 45%;
                    display: flex;
                    flex-direction: column;
                    margin-left: auto;
                }

                .offer_wrap {
                    display: flex;
                    position: relative;
                    justify-content: center;

                    @media (max-width: $desktop-breakpoint) {
                        margin-top: 10px;
                        margin-bottom: 20px;

                        &:before {
                            content: '';
                            display: block;
                            height: 150px;
                            background: #f4f4f4;
                            position: absolute;
                            z-index: 0;
                            top: -138px;
                            left: -25px;
                            width: calc(100% + 50px);
                        }
                    }

                    small.label {
                        @media (min-width: $desktop-breakpoint) {
                            display: none;
                        }
                    }
                }

                h4 {
                    @media (min-width: $desktop-breakpoint) {
                        display: none;
                    }

                    font-size: 3rem;
                    text-align: center;
                    line-height: 1;
                    color: #434040;
                    display: block;
                    min-height: 70px;
                    margin-bottom: 0;
                    z-index: 1;
                    position: relative;
                    margin-top: 15px;

                    span {
                        font-size: 2.2rem;
                        margin-top: 15px;
                    }
                }
            }

            .offer_wrap {
                small.label {
                    z-index: 1;
                    color: #ffffff;
                    padding: 2px 10px;
                    font-size: 1.1rem;
                    border-radius: 100px;
                    display: inline-block;
                    background: #3b976e;
                    text-transform: uppercase;
                }
            }

            p {
                font-size: 20px;
                margin-bottom: 20px;
                position: relative;

                @media (min-width: $desktop-breakpoint) {
                    margin-bottom: 20px;
                }

                &:before {
                    content: ' ';
                    background: white;
                }
            }

            button.question-answer-button {
                width: 100%;
            }
        }

        small.disclaimer {
            display: block;
            font-size: 9px;
            color: #47474d;
            line-height: 1.5;
            padding-top: 2rem;
            text-align: left !important;
            padding: 20px;

            @media (min-width: $desktop-breakpoint) {
                padding: 30px;
            }
        }
    }

    h2.offer-title {
        font-family: 'Lora', serif;
        margin: 0 auto 15px;

        max-width: 760px;
        font-size: 48px;
        line-height: 61px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
    }

    .offer-footer {
        text-align: left;
        font-size: 11px;
        color: #a6a6a6;
        max-width: 560px;
        margin: 0 auto;
        font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
        display: block;
    }
}

a.skip-link {
    cursor: pointer;
    padding: 5px 25px;
    border: 1px solid #979797;
    border-radius: 10rem;
    display: inline-block;
    color: #979797;
    width: auto !important;
    cursor: pointer;
    max-width: 190px;
    margin: auto;
    margin-top: 25px;
}

/* make sure the content has the screen size fixing overlay offer image background issues */
.container.content .row:first-child {
    min-height: 100vh;
}

/* inherit offer background by default for a better UX */
.ai-content--offer {
    background-color: inherit;
}

/* header logo css fix for showing up logo properly with 100% and 62.5% font-size  */
@media (min-width: 1024px) {
    .header__logo {
        padding-left: 8.5rem;
        position: initial;
    }

    .header__top {
        height: auto;
    }
}

.skip-link {
    font-size: 16px;
}

/* header top margin & padding fix for desktop */

@media (min-width: 1024px) {
    .header__top {
        height: auto;
        margin-top: 0px;
        padding: 0;
    }
}

/* change phone input and button */

.row-tel-input {
    padding: 1rem 0;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0px;
    place-items: center;
    button {
        background-color: #63da8b !important;
        position: relative;

        width: 32px !important;
        height: 32px !important;
        overflow: hidden;
        display: block !important;

        border-radius: 100%;
        min-width: auto !important;
        margin: 0 !important;
        border: none !important;

        transition: 200ms ease;
        &::after {
            content: '';

            position: absolute;
            opacity: 1;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 16px;
            border: solid 1px white;
            border-width: 0 3px 3px 0;
            margin-top: -2px;
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::before {
            content: '';

            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #63da8b;
        }
    }
}

/* YES/NO CREATIVE */
[ct-template='template-6'] #ct-headline p,
[ct-template='template-6'] #ct-headline div {
    padding: 0;
    font-stretch: semi-condensed;
    color: #2b282f;
    font-family: 'Lora', serif;
    font-weight: 500;
    font-size: 34px;
}
[ct-template='template-6'] #ct-subheadline p,
[ct-template='template-6'] #ct-subheadline div {
    padding: 0;
    color: #4a4453;
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    margin: 20px auto;
    font-weight: 700;
}
[ct-template='template-6'] #ct-main-description p,
[ct-template='template-6'] #ct-main-description div {
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    color: #a6a6a6;
    margin: 0 auto;
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    max-width: 425px;
}
[ct-template='template-6'] #actions {
    padding: 0;
    max-width: 425px;
    margin: 50px auto 0 auto;
}
[ct-template='template-6'] #actions #ct-creative-submit-yes {
    transition: background-color 0.3s ease-in-out;
    padding-left: 1rem;
    opacity: 100;
    border-radius: 0;
    background-color: #c24a42;
    border: none;
    border-bottom: 4px solid #8f342e;
    box-shadow: none;
    margin-bottom: 25px;
    height: 65px;
    padding: 0;
}
[ct-template='template-6'] #actions #ct-creative-submit-yes span {
    text-align: center;
    width: 100%;
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: normal;
    font-family: sans-serif;
}
[ct-template='template-6'] #actions #ct-creative-submit-no {
    opacity: 100;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #a6a6a6;
    border-bottom: 4px solid #a6a6a6;
    transition: ease-in box-shadow 160ms, ease-in border 160ms;
    height: 65px;
    padding: 0;
}
[ct-template='template-6'] #actions #ct-creative-submit-no span {
    text-align: center;
    width: 100%;
    color: black;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: normal;
    font-family: sans-serif;
}
/* ./ YES/NO CREATIVE */
